export const setWidth = () => ({
  type: "setWidth",
});

export const setScroll = () => ({
  type: "setScroll",
});

export const setLoading = (status: boolean) => ({
  type: "setLoading",
  data: status,
});
