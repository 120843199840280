import { envVars } from "./envVars";

const { appUrl, apiBase } = envVars;

export const ReactRoutes = {
  login: appUrl + "",
  dashboard: appUrl + "dashboard",
  superCategory: appUrl + "super-category",
  category: appUrl + "category",
  batch: appUrl + "batch",
  product: appUrl + "product",
  orders: appUrl + "orders",
};

export const ApiRoutes = {
  login: apiBase + "/user/login/email",
  getUserData: apiBase + "/user/getUserData",
  forgetPassword: apiBase + "/user/forgot",
  addSuperCategory: apiBase + "/category/addSuperCategory",
  addCategory: apiBase + "/category/addCategory",

  addBatch: apiBase + "/batch/add",
  getBatch: apiBase + "/batch/get",

  getOrders: apiBase + "/order/admin/get",

  addProduct: apiBase + "/product/add",
  getProduct: apiBase + "/product/getAdmin", // send id in param if want to fetch single product
  deleteProduct: apiBase + "/product/delete", // id required in params
  getAllCategory: apiBase + "/category/getAllCategory",
  updateProduct: apiBase + "/product/update/", // product id in params and q in body
  updatePrice: apiBase + "/product/update-price/", // product id in params and price in body

  uploadImage: apiBase + "/file/upload-image",
};
