export const envVars = {
  apiBase: `${process.env.REACT_APP_API_BASE_URL}`,
  nodeEnv: `${process.env.NODE_ENV}`,
  appUrl: `${process.env.REACT_APP_PUBLIC_URL}`,
  s3: {
    bucketName: `${process.env.REACT_APP_S3_BUCKET}`,
    region: `${process.env.REACT_APP_S3_BUCKET_REGION}`,
    accessKeyId: `${process.env.REACT_APP_S3_ACCESS_KEY_ID}`,
    secretAccessKey: `${process.env.REACT_APP_S3_SESSION_ACCESS_KEY}`,
    // dirName: `${process.env.REACT_APP_S3_DIR_NAME}`,
  },
};

export const axiosCfg = {
  withCredentials: true,
  headers: {
    authorization: `Token ${window.localStorage.getItem("token")}`,
    "Access-Control-Allow-Origin": "*",
  },
};

export const staticUrl = "https://static.bbgroyals.com";
