import * as React from "react";
import { Divider, Menu } from "antd";
import { navigations } from "../../configs/navigations";
import Title from "antd/lib/typography/Title";
import { Link } from "react-router-dom";
import SubMenu from "antd/lib/menu/SubMenu";
import { toString } from "lodash";

export const Sidebar = () => {
  return (
    <Menu mode="vertical" selectedKeys={[]} theme="dark">
      {navigations.map((value, key) => {
        return (
          <div key={key} className="mb-5 mt-3">
            <Title
              level={5}
              className="has-text-white is-flex is-align-items-center pl-2 mb-3"
            >
              {value.title}
            </Title>
            {value.links.map((value1, key1) => {
              return (
                <React.Fragment key={toString(key) + toString(key1)}>
                  {value1.link ? (
                    <Menu.Item key={value1.link}>
                      <Link
                        to={value1.link}
                        className="is-flex is-align-items-center"
                      >
                        {value1.icon}&nbsp;{value1.title}
                      </Link>
                    </Menu.Item>
                  ) : (
                    <SubMenu>
                      {value1.subLinkTypes?.map((value2, key2) => {
                        return (
                          <div
                            key={
                              toString(key) + toString(key1) + toString(key2)
                            }
                            className="has-text-white is-flex is-align-items-center is-flex-direction-row"
                          >
                            work pending
                          </div>
                        );
                      })}
                    </SubMenu>
                  )}
                </React.Fragment>
              );
            })}
            <div
              style={{
                padding: "0 10px",
              }}
            >
              <Divider className="divider-color" />
            </div>
          </div>
        );
      })}
    </Menu>
  );
};
