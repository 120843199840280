import { Button, Card, Form, Input, message, Modal } from "antd";
import Password from "antd/lib/input/Password";
import Title from "antd/lib/typography/Title";
import {
  MdLockOpen,
  MdMail,
  MdOutlinePerson,
  MdOutlineVpnKey,
} from "react-icons/md";
import * as React from "react";
import { useSelector } from "react-redux";
import { ApiRoutes, ReactRoutes } from "../../configs/urls";
import axios from "axios";
import { Cookies } from "react-cookie";
import { axiosCfg, staticUrl } from "../../configs/envVars";
import { useNavigate } from "react-router-dom";

const { useForm } = Form;

export const Login = () => {
  const width = useSelector((state: any) => state.ui.width);
  const [modal, setModal] = React.useState<boolean>(false);
  const cookies = new Cookies();
  const [form] = useForm();
  const [loginLoading, setLoginLoading] = React.useState(false);
  const [resetLoading, setResetLoading] = React.useState(false);
  const navigate = useNavigate();

  const login = (formData: any) => {
    setLoginLoading(true);
    axios
      .post(
        ApiRoutes.login,
        {
          email: formData.email,
          password: formData.password,
        },
        axiosCfg
      )
      .then((res) => {
        const data = res.data;
        if (data.success) {
          console.log(data);
          if (data.role === "admin") {
            message.success("Login Successful");
            cookies.set("auth", data.token, {
              expires: new Date(new Date().setMonth(new Date().getMonth() + 1)),
            });
            window.localStorage.setItem("token", `${data.token}`);
            setTimeout(() => {
              navigate(ReactRoutes.dashboard);
            }, 1000);
            setLoginLoading(false);
          } else {
            message.error(
              "Unauthenticated user! You don't have access to execute tasks here"
            );
            setLoginLoading(false);
          }
        } else {
          message.error(data.message);
          setLoginLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoginLoading(false);
      });
  };

  const ForgetPassword = async (formData: any) => {
    setResetLoading(true);
    await axios.post(
      ApiRoutes.forgetPassword + "?ui=super",
      {
        email: formData.email,
      },
      axiosCfg
    );

    form.resetFields();
    message.warning(
      "Please wait for while will receive mail regarding new password"
    );
    setModal(false);
    setResetLoading(false);
  };

  return (
    <div className="is-flex is-justify-content-center mt-5 py-4">
      <Modal
        title="Forgot Password"
        visible={modal}
        okText="Send Email"
        okButtonProps={{
          htmlType: "submit",
          onClick: () => form.submit(),
          loading: resetLoading,
        }}
        onCancel={() => setModal(false)}
      >
        <Form autoComplete="off" form={form} onFinish={ForgetPassword}>
          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                required: true,
                message: "Please Enter valid email to change the password",
              },
            ]}
          >
            <Input
              placeholder="Enter email to change password"
              prefix={<MdMail />}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Card
        className="is-flex is-justify-content-center is-flex-direction-column py-5 small-shadow"
        style={{
          width: width >= 1024 ? "40%" : width >= 768 ? "75%" : "95%",
          borderRadius: "10px",
        }}
        hoverable
        title={
          <>
            <figure className="image is-3by3 is-flex is-justify-content-center mb-3">
              <img
                src={`/logo.png`}
                alt="BBG Royals"
                style={{
                  width: "100px",
                }}
              />
            </figure>
            <Title level={3} className="has-text-centered">
              Login
            </Title>
          </>
        }
      >
        <div className="is-flex is-justify-content-center">
          <Form
            autoComplete="on"
            onFinish={login}
            autoSave="on"
            style={{
              width: width >= 1024 ? "75%" : "90%",
            }}
          >
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Please Enter valid email",
                },
              ]}
            >
              <Input
                prefix={<MdOutlinePerson />}
                size="large"
                placeholder="Your Username"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please Enter valid password",
                },
              ]}
            >
              <Password
                prefix={<MdOutlineVpnKey />}
                size="large"
                placeholder="Your Password"
                autoComplete="off"
              />
            </Form.Item>
            <Form.Item className="mt-5">
              <Button
                htmlType="submit"
                icon={<MdLockOpen />}
                className="button is-primary"
                loading={loginLoading}
              >
                Dive In
              </Button>
            </Form.Item>
            <Button
              type="text"
              onClick={() => {
                setModal(true);
              }}
            >
              Forgot Password?
            </Button>
          </Form>
        </div>
      </Card>
    </div>
  );
};
