import { LoadingOutlined } from "@ant-design/icons";
import * as React from "react";
import { Spin } from "antd";

export const Loading = (props: { simple?: boolean; text?: string }) => {
  const width = window.innerWidth;
  return (
    <>
      {props.simple ? (
        <div
          style={{
            textAlign: "center",
            margin: "1rem 0",
          }}
        >
          <Spin size="large" indicator={<LoadingOutlined spin />} />
          <h5
            style={{
              fontWeight: "normal",
            }}
          >
            {props.text ? props.text : "Loading..."}
          </h5>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            height: "100vh",
            position: "fixed",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            top: 0,
            left: 0,
            backgroundColor: "#44444455",
            zIndex: 999,
          }}
        >
          <div
            style={{
              width: width > 1200 ? "30%" : width > 600 ? "75%" : "90%",
              height: "30%",
              background: "#FFFFFF",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Spin size="large" indicator={<LoadingOutlined spin />} />
            <h5
              style={{
                fontWeight: "normal",
                marginTop: "1rem",
              }}
            >
              {props.text ? props.text : "Loading..."}
            </h5>
          </div>
        </div>
      )}
    </>
  );
};
