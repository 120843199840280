import { MdBatchPrediction, MdDashboard, MdListAlt } from "react-icons/md";
import { RiTempHotLine } from "react-icons/ri";
import { NavigationType } from "../types/navigations";
import { ReactRoutes } from "./urls";

const iconSize = 20;

export const navigations: NavigationType[] = [
  {
    title: "Home",
    links: [
      {
        title: "Dashboard",
        link: ReactRoutes.dashboard,
        icon: <MdDashboard size={iconSize} />,
      },
    ],
  },
  {
    title: "Collection",
    links: [
      {
        title: "Batch",
        link: ReactRoutes.dashboard,
        icon: <MdBatchPrediction size={iconSize} />,
      },
      {
        title: "Products",
        link: ReactRoutes.product,
        icon: <RiTempHotLine size={iconSize} />,
      },
      {
        title: "Orders",
        link: ReactRoutes.orders,
        icon: <MdListAlt size={iconSize} />,
      },
    ],
  },
];
