import { Layout } from "antd";
import { Content } from "antd/lib/layout/layout";
import Sider from "antd/lib/layout/Sider";
import React, { Suspense, useCallback, useState } from "react";
import ReactDOM from "react-dom";
import { Provider, useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Login } from "./component/login/login";
import "./styles/global.scss";
import { isSider } from "./configs/sider";
import { ReactRoutes, ApiRoutes } from "./configs/urls";
import { setScroll, setWidth } from "./actions/ui";
import { Sidebar } from "./component/sidebar/sidebar";
import { Loading } from "./component/extra/loading";
import { Cookies } from "react-cookie";
import axios from "axios";
import { axiosCfg } from "./configs/envVars";
import { setUserData } from "./actions/api";
import store from "./reducers/root";

const Product = React.lazy(() => import("./component/product/product"));
const Orders = React.lazy(() => import("./component/orders"));
const NOT_FOUND = React.lazy(() => import("./component/404"));
const OrderInfo = React.lazy(() => import("./component/orders/order-info"));

const LayoutManager = () => {
  const sider = isSider(useLocation().pathname);
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const path = useLocation().pathname;

  React.useEffect(() => {
    window.addEventListener("scroll", () => {
      dispatch(setScroll());
    });
  });

  React.useEffect(() => {
    window.addEventListener("resize", () => {
      dispatch(setWidth());
    });
  });

  React.useEffect(() => {
    window.addEventListener("close", () => {
      window.localStorage.removeItem("token");
      cookies.remove("auth");
    });
  });

  const verifyLogin = useCallback(
    async () => {
      setLoading(true);
      let result = false;

      let token: string | null = null;
      if (cookies.get("auth") !== undefined && cookies.get("auth") !== null) {
        token = cookies.get("auth");
      } else {
        token = window.localStorage.getItem("token")
          ? window.localStorage.getItem("token")
          : null;
      }
      if (token !== null) {
        await axios
          .get(ApiRoutes.getUserData, axiosCfg)
          .then((res) => {
            if (res.data.success === false) {
              window.localStorage.removeItem("token");
              cookies.remove("auth");
              navigate(ReactRoutes.login);
              setLoading(false);
              result = true;
            } else {
              dispatch(setUserData(res.data.user));
              if (path === ReactRoutes.login) navigate(ReactRoutes.dashboard);
            }
          })
          .catch((err) => {
            window.localStorage.removeItem("token");
            cookies.remove("auth");
            navigate(ReactRoutes.login);
          });
      } else {
        cookies.remove("auth");
        window.localStorage.removeItem("token");
        navigate(ReactRoutes.login);
      }
      if (!result) {
        cookies.remove("auth");
        window.localStorage.removeItem("token");
        navigate(ReactRoutes.login);
      }
      setLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  React.useEffect(() => {
    verifyLogin();
  }, [verifyLogin]);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Loading simple />
        </div>
      ) : (
        <Layout
          style={{
            height: "100vh",
            flexDirection: "row",
          }}
        >
          {sider && (
            <Sider
              style={{
                overflowY: "auto",
              }}
            >
              <Sidebar />
            </Sider>
          )}

          <Content
            style={{
              overflowY: "auto",
            }}
          >
            <Routes>
              <Route path={ReactRoutes.login} element={<Login />} />
              <Route path={ReactRoutes.product} element={<Product />} />
              <Route path={ReactRoutes.orders} element={<Orders />} />
              <Route
                path={`${ReactRoutes.orders}/:id`}
                element={<OrderInfo />}
              />
              <Route path="*" element={<NOT_FOUND />} />
            </Routes>
          </Content>
        </Layout>
      )}
    </>
  );
};

const Main = () => {
  return (
    <Provider store={store}>
      <Suspense fallback={<Loading simple />}>
        <Router>
          <LayoutManager />
        </Router>
      </Suspense>
    </Provider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>,
  document.getElementById("root")
);
