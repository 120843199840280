const state = {
  width: window.innerWidth,
  scroll: window.scrollY,
  loading: false,
};

export const UiReducer = (initialState = state, action: any) => {
  switch (action.type) {
    case "setWidth":
      return { ...initialState, width: window.innerWidth };
    case "setScroll":
      return { ...initialState, scroll: window.scrollY };
    case "setLoading":
      return { ...initialState, loading: action.data };
    default:
      return initialState;
  }
};
