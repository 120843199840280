const state = {
  userData: {},
};

export const apiReducer = (initialState = state, action: any) => {
  const type = action.type;
  const data = action.data;
  switch (type) {
    case "SET_USER_DATA":
      return { ...initialState, userData: data };
    default:
      return initialState;
  }
};
